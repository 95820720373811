.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectwrapper {
    margin: 20px;
}

.select {
    font-size: 18px;
    height: 30px;
    margin: auto 10px;
}

.scroll {
    width: 100%;
}

.table {
    margin: auto;
    width: 80%;
}

.th {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    font-size: 20px;
}

.td {
    background-color: rgb(236, 236, 236);
    font-size: 20px;
}

.td2 {
    background-color: rgb(205, 241, 255);
    font-size: 20px
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h2 {
        font-size: 18px;
    }

    .selectwrapper {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin: 20px;
    }

    .select {
        font-size: 14px;
        height: 30px;
        margin: 3px;
    }

    .scroll {
        overflow-x: scroll;
    }

    .table {
        min-width: 100%;
        border-collapse: collapse;
    }

    .th {
        font-size: 12px;
    }

    .td {
        font-size: 12px;
    }

}

@media screen and (max-width: 480px) {
    .table {
        width: 130%;
    }
}