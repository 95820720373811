.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.p {
    font-size: 18px;
}

.table {
    margin: 30px auto;
    width: 65%;
    border-collapse: collapse;
}

.th {
    background-color: rgb(151, 206, 255);
    font-size: 18px;
}

.td {
    font-size: 18px;
}

.tr:nth-child(odd) .td {
    background: rgb(227, 227, 227);
}

.mailform {
    margin: 0 auto;
    border: solid 1px black;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label {
    display: flex;
    align-items: baseline;
}

.input {
    font-size: 18px;
    height: 30px;
    width: 450px;
}

.textarea {
    font-size: 18px;
    width: 450px;
    height: 300px;
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h3 {
        font-size: 18px;
    }

    .table {
        width: 95%;
        margin: 15px;
    }

    .p {
        font-size: 14px;
    }

    .th {
        background-color: rgb(151, 206, 255);
        font-size: 14px;
    }

    .td {
        font-size: 14px;
    }

    .mailform {
        width: 95%;
    }

    .label {
        width: 90%;
        font-size: 14px;
    }

    .input {
        font-size: 14px;
        height: 30px;
        width: 80%;
    }

    .textarea {
        font-size: 14px;
        width: 80%;
        height: 200px;
    }
}