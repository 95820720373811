.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputWrapper {
    display: flex;
}

.p {
    font-size: 18px;
    margin: 30px auto 10px auto;
}

.radio {
    margin: 0 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.label {
    margin: 20px auto;
    font-size: 18px;
}

.select {
    font-size: 18px;
    height: 30px;
}

.time {
    font-size: 18px;
}

.input {
    font-size: 18px;
}

.detail {
    font-size: 16px;
}

.div2 {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: azure;
    border: dotted 1px black;
}

.div3 {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: azure;
}

.table_p {
    margin: 0 30px;
}

.span {
    font-size: 14px;
    margin: 5px;
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .div2 {
        width: 100%;
    }

    h2 {
        font-size: 18px;
    }

    .p {
        margin: 10px;
        font-size: 14px;
    }

    .detail {
        font-size: 14px;
    }

    .label {
        font-size: 13px;
        margin: 2px;
    }

    .table_p {
        margin: 0;
    }
}