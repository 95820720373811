.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select {
    font-size: 18px;
    height: 30px;
    margin: auto 10px;
}

.table {
    margin: 20px auto;
    width: 90%;
    /* border-collapse: collapse; */
    font-size: larger;
}

.th {
    background-color: rgb(151, 206, 255);
    font-size: 20px;
}

.td {
    font-size: 20px;
    background: rgb(233, 233, 233);
}

.tdp {
    margin: 3px;
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 100px;
    }

    .div2 {
        overflow-x: scroll;
    }

    .table {
        width: 100%;
    }

    .th {
        font-size: 14px;
    }

    .td {
        font-size: 14px;
    }

    h2 {
        font-size: 18px;
    }

}

@media screen and (max-width: 480px) {
    .table {
        width: 140%;
    }
}