.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
}

span {
    height: 15px;
    color: red;
    font-size: small;
}

.input {
    height: 30px;
    width: 300px;
    font-size: large;
}

.select {
    font-size: 18px;
    height: 30px;
    margin: 10px;
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h2 {
        font-size: 18px;
    }

    .p {
        width: 90%;
        font-size: 14px;
    }

    .input {
        width: 200px;
        font-size: 14px;
    }

    .select {
        font-size: 14px;
    }
}