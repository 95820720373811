.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scroll {
    width: 100%;
}

.selecterwrapper {
    display: flex;
    align-items: center;
    margin: 20px;
    justify-content: center;
}

.select {
    font-size: 18px;
    height: 30px;
    margin: 10px;
    background-color: white;
    border: 1px solid #222;
}

.table {
    margin: 20px auto;
    width: 90%;
    border-collapse: collapse;
    font-size: large;
}

.th {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}

.td1 {
    border-bottom: solid rgb(179, 179, 179) 1px;
}

.td {
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}

.tr:nth-child(odd) .td {
    background: rgb(227, 227, 227);
}

@media screen and (max-width:959px) {
    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h2 {
        font-size: 18px;
    }

    .selectwrapper {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin: 20px;
    }

    .select {
        font-size: 14px;
        height: 30px;
        margin: 3px;
    }

    .scroll {
        overflow-x: scroll;
    }

    .table {
        width: 140%;
        border-collapse: collapse;
    }

    .th {
        font-size: 11px;
    }

    .td1 {
        font-size: 11px;
    }

    .td {
        font-size: 11px;
    }

    .p {
        white-space: break-spaces;
        font-size: 14px;
    }

    .label {
        white-space: break-spaces;
        font-size: 14px;
    }
}