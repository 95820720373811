.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scroll {
    width: 100%;
}

.table {
    margin: 20px auto;
    width: 90%;
    border-collapse: collapse;
}

.th {
    background-color: rgb(151, 206, 255);
    font-size: 18px;
    border: 1px solid black;
}

.td {
    font-size: 18px;
    border: 1px solid black;
    text-align: center;
}

.td2 {
    width: 120px;
}

.tr:nth-child(odd) .td {
    background: rgb(227, 227, 227);
}

@media screen and (max-width: 1320px) {

    .table {
        width: 100%;
    }

    .th {
        font-size: 12px;
    }

    .td {
        font-size: 12px;
    }
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h2 {
        font-size: 18px;
    }

    .table {
        min-width: 100%;
    }

    .scroll {
        overflow-x: scroll;
    }
}

@media screen and (max-width: 750px) {
    .table {
        min-width: 140%;
    }
}

@media screen and (max-width: 530px) {
    .table {
        min-width: 200%;
    }
}