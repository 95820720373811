.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
}

.span {
    height: 15px;
    color: red;
    font-size: small;
}

.input {
    height: 28px;
    width: 300px;
    font-size: large;
}

.table {
    margin: 20px auto;
    width: 80%;
    border-collapse: collapse;
    font-size: large;
}

.th {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}

.td {
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}

.tr:nth-child(odd) .td {
    background: rgb(227, 227, 227);
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h2 {
        font-size: 18px;
    }

    .table {
        width: 95%;
        border-collapse: collapse;
    }

    .th {
        width: 70%;
        font-size: 14px;

    }

    .td {
        font-size: 14px;
    }

    .p {
        white-space: break-spaces;
        font-size: 14px;
    }
}