.div {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    border: 1px black solid;
}

.input {
    margin: 15px;
    font-size: 18px;
}

.p {
    white-space: break-spaces;
    font-size: 18px;
}

.label {
    white-space: break-spaces;
    font-size: 18px;
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }
}