.nav {
    min-width: 300px;
}

.check {
    display: none;
}

.sidebar {
    position: fixed;
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: x-large;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    color: rgb(55, 55, 55);
    background-color: rgb(240, 240, 240);
}

.pending {
    color: red;
}

.active .list {
    background-color: rgb(185, 185, 185);
    color: rgb(55, 55, 55);
    text-decoration: underline;
}

.active .togglelist {
    background-color: rgb(185, 185, 185);
    color: rgb(55, 55, 55);
    text-decoration: underline;
}

.link {
    width: 100%;
    color: rgb(55, 55, 55);
    text-decoration: none;
}

.list {
    padding: 10px;
}

.toggleUl {
    margin-left: 50px;
    font-size: 16px;
    font-weight: 100;
    list-style: none;
}

.togglelist:hover {
    background-color: rgb(210, 255, 238);
    cursor: pointer;
}

.togglelist {
    padding: 5px;
}

.list:hover {
    cursor: pointer;
    background-color: rgb(210, 255, 238);
}

.liOpen {
    display: flex;
    padding: 10px;
}

.liOpen:hover {
    cursor: pointer;
    background-color: rgb(210, 255, 238);
}

.ul {
    list-style: none;
}

.ul2 {
    list-style: none;
    margin: 200px 0px;
}

.header {
    display: none;
}

@media screen and (max-width: 959px) {

    .nav {
        min-height: 0;
    }

    .header {
        top: 0px;
        position: fixed;
        width: 100%;
        height: 45px;
        background: linear-gradient(#ddebff, rgb(241, 253, 255));
        border-bottom: 1px rgb(180, 180, 180) solid;
        opacity: 85%;
        display: flex;
        z-index: 999;
    }

    .sticky {
        position: static;
    }

    .sidebar {
        position: absolute;
        width: 300px;
        right: -350px;
        top: 0px;
        transition: all 0.4s;
    }

    .check:checked~.sidebar {
        position: fixed;
        width: 300px;
        right: 0px;
        top: 0px;
        display: block;
        z-index: 1000;
    }

    .check:checked~.back {
        position: absolute;
        left: 0;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 100;
    }

}

@media screen and (max-width:480) {}