.App {
    display: flex;
    min-height: 100%;
}



@media screen and (max-width: 959px) {
    .App {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        overflow-x: hidden;
    }

}