.div {
    width: 80%;
}

.calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dayMatrix {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.td {
    font-size: large;
    width: 100%;
    min-height: 30px;
    text-align: left;
    align-content: baseline;
    border-bottom: rgb(180, 180, 180) solid 1px;
}

.td1 {
    font-size: large;
    color: red;
    width: 100%;
    height: 30px;
    text-align: left;
    align-content: baseline;
    border-bottom: rgb(180, 180, 180) solid 1px;
}

.td2 {
    font-size: large;
    color: blue;
    width: 100%;
    height: 30px;
    text-align: left;
    align-content: baseline;
    border-bottom: rgb(180, 180, 180) solid 1px;
}

.below {
    font-size: large;
    background-color: rgb(190, 190, 190);
    color: rgb(95, 95, 95);
    width: 100%;
    height: 30px;
    text-align: left;
    align-content: baseline;
    border-bottom: rgb(180, 180, 180) solid 1px;
}

.today {
    font-size: large;
    width: 100%;
    min-height: 30px;
    text-align: left;
    align-content: baseline;
    border: rgb(255, 157, 127) solid 3px;
}

.window {
    position: absolute;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background-color: white;
    border-bottom: dotted 1px rgb(140, 140, 140);
    z-index: 10;
}

.back {
    position: fixed;
    z-index: 5;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 150%;
    background-color: black;
    opacity: 10%;
}

.h4 {
    margin: 8px;
    align-self: center;
}

.label {
    margin-left: 10px;
    margin-top: 3px;
}

.select {
    font-size: 15px;
    height: 23px;
}

.card {
    background-color: rgb(230, 248, 255);
}

.mark {
    z-index: 20;
}

.input {
    width: 50px;
}