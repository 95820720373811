.div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div2 {
    display: flex;
}

p {
    white-space: break-spaces;
}

.selecterwrapper {
    display: flex;
    align-items: center;
    margin: 10px;
    justify-content: space-between;
}

.select {
    font-size: 18px;
    height: 30px;
    margin: 10px;
}

.scroll {
    width: 100%;
}

.table {
    margin: 20px auto;
    width: 80%;
    border-collapse: collapse;
    font-size: large;
}

.th {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}

.td {
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}


.tr:nth-child(odd) .td {
    background: rgb(227, 227, 227);
}

.td2 {
    background-color: rgb(205, 241, 255);
    border: 1px solid rgb(76, 76, 255);
    text-align: center;
    font-weight: bold;
}

.td3 {
    text-align: center;
    width: 50px;
}

@media screen and (max-width: 1320px) {

    .table {
        width: 90%;
    }

    .th {
        font-size: 14px;
    }

    .td {
        font-size: 14px;
    }
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 100px;
    }

    .h2 {
        font-size: 18px;
    }

    .selecterwrapper {
        flex-direction: column;
    }

    .select {
        margin: 5px;
    }

    .scroll {
        overflow-x: scroll;
    }

    .table {
        min-width: 100%;
        border-collapse: collapse;
    }

    .th {
        font-size: 12px;
    }

    .td {
        font-size: 12px;
    }

}

@media screen and (max-width: 480px) {
    .table {
        width: 160%
    }
}