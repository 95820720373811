.div {
    width: 100%;
    text-align: center;
}

.p {
    white-space: break-spaces;
    font-size: 20px;
}

.label {
    white-space: break-spaces;
    font-size: 18px;
}

.selecterwrapper {
    display: flex;
    align-items: center;
    margin: 20px;
    justify-content: center;
}

.select {
    font-size: 18px;
    height: 30px;
    margin: auto 10px;
}

.table {
    margin: 20px auto;
    width: 80%;
    border-collapse: collapse;
    font-size: large;
}

.th1 {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
    min-width: 130px;
}

.th2 {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
    min-width: 60px;
}

.th3 {
    background-color: rgb(151, 206, 255);
    border-radius: 10px 10px 0 0;
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
    min-width: 30px;
}

.td {
    border-bottom: solid rgb(179, 179, 179) 1px;
    font-size: 18px;
}

.td2 {
    border-bottom: solid rgb(179, 179, 179) 1px;
    width: 50px;
}

.tr:nth-child(odd) .td {
    background: rgb(227, 227, 227);
}

.tr:nth-child(odd) .td2 {
    background: rgb(227, 227, 227);
}

@media screen and (max-width: 959px) {

    .div {
        width: 100%;
        padding-top: 45px;
    }

    .h2 {
        font-size: 18px;
    }

    .selectwrapper {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin: 20px;
    }

    .select {
        font-size: 14px;
        height: 30px;
        margin: 3px;
    }

    .scroll {
        overflow-x: scroll;
    }

    .table {
        width: 130%;
        border-collapse: collapse;
    }

    .th1 {
        font-size: 11px;
        min-width: 140px;
    }

    .th2 {
        font-size: 11px;
        min-width: 60px;
    }

    .th3 {
        font-size: 11px;
        min-width: 40px;

    }

    .td {
        font-size: 11px;
    }


    .p {
        white-space: break-spaces;
        font-size: 14px;
    }

    .label {
        white-space: break-spaces;
        font-size: 14px;
    }

}